<template>
  <div v-if="isShow">
    <BaseModal>
      <div class="voteBox flex-column-center">
        <img
          class="closeBtn"
          src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/closeBtn.png"
          alt=""
          @click="closeModal"
        >
        <van-field
          class="voteTitleInput flex-row-center"
          v-model="voteTitle"
          label=""
          placeholder="在此輸入投票主題"
          maxlength="100"
        />
        <div class="optionTag">添加投票選項</div>
        <div
          class="optionInputBox"
          v-for="(item,index) in optionList"
          :key="index"
        >
          <van-field
            class="voteOptionInput flex-row-center"
            v-model="item.tag"
            label=""
            placeholder="在此輸入投票選項"
            maxlength="100"
          />
          <img
            class="deleteOption"
            src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/deleteIcon.png"
            alt=""
            @click="removeOption(index)"
            v-if="index!==0 && index!==1"
          >
        </div>
        <div class="addOptionLine flex-row-center">
          <div
            class="addOptionBtn"
            @click="addOption"
          >添加選項</div>
          <div class="addOptionText">最多可添加20個选项</div>
        </div>
        <div class="optionTag">投票選項</div>
        <div class="checkBoxLine flex-row-center">
          <div class="checkItemBox flex-row-center">
            <img
              class="checkIcon"
              src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/check0.png"
              alt=""
              v-show="isMulti"
              @click="changeMulti(false)"
            >
            <img
              class="checkIcon"
              src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/check1.png"
              alt=""
              v-show="!isMulti"
            >
            <div class="checkItemText">單選</div>
          </div>
          <div class="checkItemBox flex-row-center">
            <img
              class="checkIcon"
              src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/check0.png"
              alt=""
              v-show="!isMulti"
              @click="changeMulti(true)"
            >
            <img
              class="checkIcon"
              src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/check1.png"
              alt=""
              v-show="isMulti"
            >
            <div class="checkItemText">多選</div>
          </div>
        </div>
        <div class="optionTag">截止时间</div>
        <div class="deadLineBox flex-row-center">
          <van-field
            class="deadLineInput flex-row-center"
            v-model="days"
            label=""
            placeholder=""
            maxlength="100"
            type="digit"
          />
          <div class="deadLineText">天後</div>
        </div>
        <div
          class="commitBtn"
          @click="goSubmit"
        >完成</div>

      </div>
    </BaseModal>

  </div>
</template>
<script type="text/ecmascript-6">
import { Toast } from 'vant'
import BaseModal from '../baseModal/BaseModal.vue'
export default {
  name: 'AddVoteModal',
  components: {
    BaseModal
  },
  data() {
    return {
      voteTitle: '',
      optionList: [{ tag: '' }, { tag: '' }],
      isMulti: false,
      days: 1,
      isShow: false
    }
  },
  methods: {
    showModal() {
      this.isShow = true
    },
    closeModal() {
      this.isShow = false
    },
    addOption() {
      this.optionList.push({ tag: '' })
    },
    removeOption(index) {
      this.optionList.splice(index, 1)
    },
    changeMulti(val) {
      this.isMulti = val
    },
    goSubmit() {
      if (!this.voteTitle) {
        Toast('请输入投票标题')
        return
      }
      for (const item of this.optionList) {
        if (!item.tag) {
          Toast('投票选项不能为空')
          return
        }
      }
      if (!this.days) {
        Toast('请输入截止天数')
        return
      }

      let voteData = {
        voteTitle: this.voteTitle,
        optionList: this.optionList,
        isMulti: this.isMulti,
        days: this.days,
        isShow: this.isShow
      }
      this.$emit('submitVote', voteData)
      this.closeModal()
    }
  }
}
</script>
<style lang="scss" scoped>
@import './AddVoteModal.scss';
</style>
<style lang="scss">
.deadLineInput {
  .van-field__control {
    text-align: center;
  }
}
</style>