<template>
  <div class="addPostPage flex-column-center">
    <van-field
      class="titleInput flex-row-center"
      v-model="title"
      label=""
      placeholder="標題寫這裡（最多30個字）"
      maxlength="30"
    />
    <div class="contentBox">

      <van-field
        class="contentInput"
        v-model="content"
        label=""
        placeholder="寫下你此刻的心情"
        maxlength="3000"
        show-word-limit
        type="textarea"
        rows="9"
      />
      <div class="dividerBox flex-column-center">
        <div class="dividerLine"></div>
      </div>
      <div class="optionLine flex-row-center">
        <van-uploader
          :after-read="afterReadImg"
          :max-size="1024*1024*600"
          @oversize="onOversize"
          :before-read="beforeRead"
        >
          <img
            class="optionBtn"
            src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/upImg.png"
            alt=""
          >
        </van-uploader>

        <img
          class="optionBtn"
          src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/upVote.png"
          alt=""
          @click="addVote"
        >
        <van-uploader
          :after-read="afterReadVideo"
          accept=".mp4,.mpeg4"
          :disabled="videoUrl&&videoUrl.length>0"
          :max-size="1024*1024*600"
          @oversize="onOversize"
        >
          <img
            class="optionBtn"
            src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/upVideo.png"
            alt=""
          >
        </van-uploader>
      </div>
    </div>
    <div class="subsidiaryBox flex-column-center">
      <!-- 图片列表 -->
      <div class="imgBox flex-row-center">
        <div
          class="imgItem"
          v-for="(item ,index) in imgList"
          :key="index"
          :style="`background-image:url(${item})`"
        >
          <img
            class="deleteImgIcon"
            src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/deleteIcon.png"
            alt=""
            @click="deleteImg(index)"
          >
        </div>
        <van-uploader
          v-if="imgList.length>0 && imgList.length<6"
          :after-read="afterReadImg"
          result-type="dataUrl"
        >
          <div class="addImgListBtn flex-row-center">
            <img
              class="addImgInner"
              src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/imgIcon.png"
              alt=""
            >
          </div>
        </van-uploader>
      </div>
      <div
        class="imgListDivider"
        v-if="imgList.length>0"
      ></div>
      <!-- 视频信息 -->
      <div
        class="videoBox flex-column-center"
        v-if="videoUrl"
      >
        <img
          class="deleteVideoIcon"
          src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/deleteIcon.png"
          alt=""
          @click="deleteVideo"
        >
        <div id="player"></div>
        <div
          class="imgListDivider"
          v-if="videoUrl"
        ></div>
      </div>
      <!-- 投票信息 -->
      <div
        class="voteBox flex-column-center"
        v-if="voteData"
      >
        <img
          class="deleteIcon"
          src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/deleteIcon.png"
          alt=""
          @click="deleteVote"
        >
        <div class="voteTitle">{{voteData.voteTitle}}</div>
        <div class="voteIsMulti">{{voteData.isMulti?'多選':'單選'}}</div>
        <div
          class="voteOptionLine flex-row-center"
          v-for="(item,index) in voteData.optionList"
          :key="index"
        >
          <div class="optionText">{{item.tag}}</div>
          <img
            class="optionCheck"
            src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/check0.png"
            alt=""
          >
        </div>
        <div class="voteDivider"></div>
        <div class="deadLineTime">截止时间：{{voteData.days}}天後</div>
      </div>
    </div>

    <!-- 投票弹窗 -->
    <AddVoteModal
      ref="addVoteModal"
      @submitVote="submitVote"
    ></AddVoteModal>

    <img
      class="submitPostBtn"
      src="https://ycmall-oss.oss-cn-hangzhou.aliyuncs.com/common-static/sgs-forum-h5/submitPost.png"
      alt=""
      @click="goAddPost"
    >

    <div
      class="upLoading flex-column-center"
      v-if="isShowUploading"
    >
      <van-loading color="#e59484" />
      <div class="uploadingText">上傳中</div>
    </div>

  </div>
</template>
<script type="text/ecmascript-6">
import { resolve } from 'path'
import { Toast } from 'vant'
import { uploadFile, addPost } from '../../api/service'
import AddVoteModal from '../../components/addVoteModal/AddVoteModal.vue'
import Player from 'xgplayer'

let player

export default {
  name: 'AddPost',
  components: {
    AddVoteModal
  },
  data() {
    return {
      title: '',
      content: '',
      voteData: null,
      imgList: [],
      videoUrl: null,
      isPlayVideo: false,
      isShowUploading: false
    }
  },
  mounted() {},
  methods: {
    // 上传图片
    afterReadImg(file) {
      uploadFile({
        index: 'uupi',
        file: file.file
      }).then((res) => {
        if (res.data.code == 0) {
          this.imgList.push(res.data.data.url)
        }
      })
    },
    beforeRead() {
      if (this.imgList.length == 6) {
        Toast('最多上傳6張圖片')
        return false
      }
      return true
    },
    // 上传视频
    afterReadVideo(file) {
      this.isShowUploading = true
      uploadFile({
        index: 'uupv',
        file: file.file
      }).then((res) => {
        this.isShowUploading = false
        if (res.data.code == 0) {
          this.videoUrl = res.data.data.url
          this.$nextTick(() => {
            player = new Player({
              id: 'player',
              url: this.videoUrl,
              videoInit: true
            })
          })
        }
      })
    },
    addVote() {
      // 如果已经有投票，则不再添加
      if (this.voteData) {
        return
      }
      this.$refs.addVoteModal.showModal()
    },
    submitVote(data) {
      console.log(data)
      this.voteData = data
    },
    deleteVote() {
      this.voteData = null
    },
    deleteImg(index) {
      this.imgList.splice(index, 1)
    },
    deleteVideo() {
      this.videoUrl = null
      player = null
    },
    goAddPost() {
      let params = {
        title: this.title,
        content: this.content,
        images: this.imgList
      }
      if (this.voteData) {
        let options = []
        this.voteData.optionList.map((item) => {
          options.push(item.tag)
        })
        params.vote = {
          name: this.voteData.voteTitle,
          multi: this.voteData.isMulti,
          valid_days: this.voteData.days,
          options: options
        }
      }
      if (this.videoUrl) {
        params.video = {
          url: this.videoUrl
        }
      }
      addPost(params).then((res) => {
        if (res.code == 0) {
          Toast('發帖成功')
          history.go(-1)
        } else {
          Toast(res.msg)
        }
      })
    },
    onOversize(file) {
      Toast('文件大小不能超過 600m')
    }
  }
}
</script>
<style lang="scss" scoped>
@import './AddPost.scss';
</style>
<style lang="scss">
.titleInput {
  .van-field__control {
    color: #823627;
  }
}
.contentInput {
  .van-field__word-limit {
    color: #c09f8b;
  }
}
</style>